/* src/FlashWord.css */
.flash-word-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5rem;
  }
  
  .flash-word-container input,
  .flash-word-container button {
    margin-top: 0.5rem;
    padding: 0.5rem;
    border: 2px solid black;
  }
  
  .flash-word-container ul {
    list-style-type: none;
    padding: 0;
  }
  
  .flash-word-container li {
    margin-top: 0.5rem;
    font-weight: bold;
  }
  