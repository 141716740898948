/* styles.css */
.table {
    border-collapse: collapse;
    width: auto; /* Table width adapts to content */
    margin-top: 10px;
  }
  
  .table th, .table td {
    border: 1px solid black;
    padding: 8px;
    text-align: left;
    width: auto; /* Cell width adapts to content */
  }
  